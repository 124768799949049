import { useJwt } from '@vueuse/integrations/useJwt';

const LOGIN_TOKEN_COOKIE = 'hw_id_token';
const LOGIN_ACCESS_TOKEN_COOKIE = 'hw_access_token';
const LOGIN_REFRESH_TOKEN_COOKIE = 'hw_refresh_token';
const LOGIN_EXPIRES_IN_COOKIE = 'hw_expires_in';
const USER_PLACEHOLDER_EMAIL = 'hw_placeholder_email';
const FACEBOOK_PICTURE = 'https://hostelworld.com/uploaded_picture';

export function useAccount() {
  const nuxtApp = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();
  const envData = runtimeConfig?.public;
  const { sendUsersUpdateEvent } = useApiIterable();
  const { useImage } = useImages();

  // ### COOKIE
  const userLoginToken = useCookie(LOGIN_TOKEN_COOKIE, {
    domain: envData?.COOKIE_DOMAIN,
    path: '/',
    watch: false,
  });

  // ### COMPOSABLES
  const { useUrl } = useUrls();

  // ### STATIC VALUES
  const loggedInUser = ref(null);
  const translation = nuxtApp?.$i18n;

  // ### COMPUTED / GETTERS
  const hasLoggedInUser = computed(() => {
    return !!toValue(loggedInUser)?.email;
  });

  const getLoggedInUser = computed(() => {
    return hasLoggedInUser ? toValue(loggedInUser) : null;
  });

  const getLoggedInMenuItems = computed(() => {
    if (!hasLoggedInUser) {
      return [];
    }

    return {
      account: {
        label: translation?.t('t_ACCOUNT'),
        href: useUrl.getMyAccountPageUrlDynamic('account'),
        icon: 'home',
      },
      details: {
        label: translation?.t('t_EDITDETAILS'),
        href: useUrl.getMyAccountPageUrlDynamic('account/details'),
        icon: 'user-line',
      },
      wallet: {
        label: translation?.t('t_WALLET'),
        href: useUrl.getMyAccountPageUrlDynamic('account/wallet'),
        icon: 'wallet',
      },
      trips: {
        label: translation?.t('t_MYTRIPS'),
        href: useUrl.getMyAccountPageUrlDynamic('account/trips'),
        icon: 'map',
      },
      help: {
        label: translation?.t('t_HELP'),
        href: translation?.t('t_CONTACTUSURL'),
        icon: 'question',
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      logout: {
        label: translation?.t('t_LOGOUT'),
        icon: 'check-out',
      },
    };
  });

  // ### METHODS / ACTIONS
  const setLoggedInUser = async function () {
    const userEncodedJwt = toValue(userLoginToken);

    if (userEncodedJwt) {
      const userData = useJwt(userEncodedJwt);
      const userInfo = toValue(userData.payload);
      let url = userInfo[FACEBOOK_PICTURE] || userInfo?.picture || '';
      loggedInUser.value = {
        name: userInfo?.name,
        email: userInfo?.email,
        picture: url,
      };
      // Perform image validation asynchronously
      if (import.meta.client && url) {
        const isValid = await useImage.isImageUrlValid(url);
        url = isValid ? url : '';
        // Update the photo property after validation
        loggedInUser.value.picture = url;
      }
    }
  };

  const logout = function () {
    const userLoginIdToken = useCookie(LOGIN_TOKEN_COOKIE);
    const userLoginAccessToken = useCookie(LOGIN_ACCESS_TOKEN_COOKIE);
    const userLoginRefreshToken = useCookie(LOGIN_REFRESH_TOKEN_COOKIE);
    const userLoginExpiresInToken = useCookie(LOGIN_EXPIRES_IN_COOKIE);
    userLoginAccessToken.value = null;
    userLoginRefreshToken.value = null;
    userLoginExpiresInToken.value = null;
    userLoginIdToken.value = null;

    const genPlaceholderEmail = `${crypto.randomUUID()}.pwa@placeholder.email`;

    useCookie(USER_PLACEHOLDER_EMAIL, {
      default: () => genPlaceholderEmail,
      domain: envData?.COOKIE_DOMAIN,
      maxAge: 34560000,
      path: '/',
      secure: true,
      watch: false,
    });

    sendUsersUpdateEvent({ email: genPlaceholderEmail });

    const nationalityStorage = useLocalStorage('nationality');
    nationalityStorage.value = null;
  };

  // ### TRY TO FETCH THE LOGGED-IN USER INFORMATION
  setLoggedInUser();

  return {
    hasLoggedInUser,
    getLoggedInUser,
    getLoggedInMenuItems,
    logout,
  };
}
