import { useCurrencyStore } from '@/stores/currency';

export function useApiCities() {
  const {
    $api,
    $i18n,
  } = useNuxtApp();
  const { locale } = $i18n;

  const currencyStore = useCurrencyStore();
  const testingStore = useTestingStore();
  const dateTime = useDateTime();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};
  const getBadgeIcon = (badgeName) => {
    switch (badgeName) {
      case 'Breakfast Included':
        return { icon: 'breakfast' };
      case 'Free WiFi':
        return { icon: 'wi-fi' };
      case 'Sanitisation Badge':
        return {
          icon: 'sanitation-badge',
          illustration: 'sanitation',
        };
      case 'hoscar':
        return {
          icon: 'trophy',
          illustration: 'trophy',
        };

      case 'experience':
        return {
          icon: 'rocket',
          illustration: 'rocket',
        };
    }
  };
  const updateApiCityProperties = (propertyList) => {
    return propertyList?.map((property) => {
      property.description = property.intro;
      property.rating = property.avgRating;
      property.numReviews = property.numberReviews;
      property.distance = property.cityCenterDistance;
      if (property.badges && Array.isArray(property.badges)) {
        property.badges = property.badges.map((badge) => {
          const badgeGraphics = getBadgeIcon(badge.badgeName);
          badge.name = badge.badgeName;
          badge.icon = badgeGraphics.icon;
          badge.illustration = badgeGraphics.illustration;
          badge.showLabel = badge.displayMessage;
          return badge;
        });
      }
      property.minDormPrice = property.sharedMinPrice;
      property.minPrivatePrice = property.privateMinPrice;
      property.urlPath = `hosteldetails.php/${property.urlFriendlyName}/${property.urlFriendlyCity}/${property.id}`;
      property.imgSizes = property.imageSizes?.propertyList;
      property.latitude = property.geoCoordinates?.latitude || null;
      property.longitude = property.geoCoordinates?.longitude || null;
      return property;
    });
  };

  const getCityByCityNameAndCountryName = async (propertyType, countryName, cityName) => {
    let result = ref(null);

    const callKey = `getCityByCityNameAndCountryName${locale?.value}${propertyType}${countryName}${cityName}${userCurrency?.code}`;
    const endpoint = `city/${propertyType}/${cityName}/${countryName}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data || null;
  };

  const getCityReviewSummaries = async (cityId) => {
    let result = ref(null);

    const callKey = `getCityReviewsSummary${locale?.value}${cityId}${userCurrency?.code}`;
    const endpoint = `city-reviews/${cityId}/summary/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return {
      reviews: result?.value?.data?.reviews,
      ratings: result?.value?.data?.ratings,
    };
  };

  const getCityProperties = async (propertyType, cityId, pageNum = 1, limiter = 30, isFeatured = 0, filters = null) => {
    const defaultPaginationValue = 1;
    const page = Number.parseInt(pageNum) || defaultPaginationValue;
    const featured = Number.parseInt(isFeatured) || 0;
    const limit = Number.parseInt(limiter) || 30;

    const experimentNewSortOrder = testingStore.hasEnabledFeature('web_newSortOrder');
    const experimentNewSortOrderParticipating = testingStore.hasEnabledFeature('web_newSortOrderIsUserParticipating');

    const internalQuery = {
      limit,
      page,
      featured,
      filters,
      ...defaultQuery,
      origin: 'spapi',
      v: experimentNewSortOrderParticipating && experimentNewSortOrder ? 'variation' : 'control',
      ignoreAvailability: testingStore.hasEnabledFeature('static_ignoreAvailability') ? 1 : 0,
    };

    const callKeySpapi = `getCityProperties${locale?.value}${propertyType}${cityId}${page}${featured}${limit}${filters}${userCurrency?.code}`;
    const endpointSpapi = `city/${propertyType}/${cityId}/properties/`;
    const resultSpapi = ref(
      await $api.spapi(callKeySpapi, endpointSpapi, defaultHeaders, internalQuery, defaultParams),
    );
    const spapiData = resultSpapi?.value?.data || {};
    const isLinkupsActive = testingStore.hasEnabledFeature('web_linkupsCallouts');

    if (isLinkupsActive) {
      const linkupsVars = testingStore.getFeatureVariables('web_linkupsCallouts');
      const linkupsNumDays = linkupsVars?.numDays || 7;
      const callKeyLinkupsApi = `getCityPropertyLinkupsEvents${locale?.value}${userCurrency?.code}`;
      const endpointLinkupsApi = 'public/properties/hostel-gatherings/stats';
      const ids = spapiData.properties?.map((property) => property.id).join(',');
      const queryLinksupsApi = {
        page,
        propertyIds: ids,
        startDate: dateTime.formatToFlat(dateTime.today),
        endDate: dateTime.formatToFlat(dateTime.addDaysToDate(dateTime.today, linkupsNumDays)),
      };
      const resultLinkupsApi
        = (await $api.linkupsApi(
          callKeyLinkupsApi,
          endpointLinkupsApi,
          defaultHeaders,
          queryLinksupsApi,
          defaultParams,
        )) || {};
      if (resultLinkupsApi.length > 0) {
        const linkupsData = resultLinkupsApi.reduce((acc, item) => {
          acc[item.propertyId] = item;
          return acc;
        }, {});
        spapiData.properties = spapiData.properties?.map((property) => {
          property.linkups = linkupsData[property.id] || {};
          return property;
        });
      }
    }

    const cityPropertiesData = {
      totalPropertiesCount: spapiData?.totalPropertiesCount || 0,
      numberOfPages: spapiData?.numberOfPages || 1,
      page: spapiData?.page || 1,
      properties: updateApiCityProperties(spapiData.properties),
    };

    return cityPropertiesData || null;
  };

  const getCountryTopCities = async (countryId) => {
    let result = ref(null);

    const callKey = `getCountryTopCities${locale?.value}${countryId}${userCurrency?.code}`;
    const endpoint = `top/cities/${countryId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));
    return result?.value?.data?.cities || null;
  };

  const getCityDistricts = async (propertyType, cityId) => {
    let result = ref(null);

    const callKey = `getCityDistricts${locale?.value}${propertyType}${cityId}${userCurrency?.code}`;
    const endpoint = `city/${propertyType}/${cityId}/districts/`;

    const internalQuery = {
      ...defaultQuery,
      ignoreAvailability: testingStore.hasEnabledFeature('static_ignoreAvailability') ? 1 : 0,
    };

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));
    return result?.value?.data?.districts || null;
  };

  const getNearestCityByCoordinates = async (latitude, longitude) => {
    let result = ref(null);

    const callParams = {
      ...defaultParams,
      latitude,
      longitude,
    };

    const callKey = `getNearestCityByCoordinates${locale?.value}${latitude}${longitude}`;
    const endpoint = 'nearest-city/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, callParams));
    return result?.value?.data || null;
  };

  const getTopPropertiesByCityId = async (propertyType, cityId) => {
    let result = ref(null);
    const callKey = `getTopPropertiesByCityId${locale?.value}${propertyType}${cityId}${userCurrency?.code}`;
    const endpoint = `top/properties/${propertyType}/${cityId}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return updateApiCityProperties(result?.value?.data.properties) || null;
  };

  return {
    getCityByCityNameAndCountryName,
    getCityProperties,
    getCityDistricts,
    getCountryTopCities,
    getCityReviewSummaries,
    getNearestCityByCoordinates,
    getTopPropertiesByCityId,
  };
}
